<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护管理规划信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="编制和公布状态" prop="bzhgbzt">
                    <el-select
                        v-model="formdata.saveJson.bzhgbzt"
                        filterable
                        clearable
                        :disabled="!isEdit"
                    >
                        <el-option label="在编" value="0"></el-option>
                        <el-option label="通过审批待公布" value="3"></el-option>
                        <el-option label="已公布且现行" value="1"></el-option>
                        <el-option label="已过期" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保护管理规划名称">
                    <el-input
                        v-model="formdata.saveJson.mc"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="组织编制单位">
                    <el-input
                        v-model="formdata.saveJson.zzbzdw"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="编制单位">
                    <el-input
                        v-model="formdata.saveJson.bzdw"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    v-if="
                        ['1', '2', '3'].indexOf(formdata.saveJson.bzhgbzt) > -1
                    "
                    label="规划期限"
                >
                    <el-col :span="11">
                        <el-date-picker
                            v-model="formdata.saveJson.ghksnf"
                            align="right"
                            value-format="yyyy"
                            type="year"
                            placeholder="选择年"
                            :disabled="!isEdit"
                        ></el-date-picker>
                    </el-col>
                    <el-col :span="2" style="text-align: center">-</el-col>
                    <el-col :span="11">
                        <el-date-picker
                            v-model="formdata.saveJson.ghjznf"
                            align="right"
                            value-format="yyyy"
                            type="year"
                            placeholder="选择年"
                            :disabled="!isEdit"
                            :picker-options="pickerOptionsend"
                        ></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item
                    v-if="
                        ['0', '1', '3'].indexOf(formdata.saveJson.bzhgbzt) > -1
                    "
                    label="是否为修编"
                    prop="sfwxb"
                >
                    <el-select
                        v-model="formdata.saveJson.sfwxb"
                        filterable
                        clearable
                        :disabled="!isEdit"
                    >
                        <el-option value="是"></el-option>
                        <el-option value="否"></el-option>
                    </el-select>
                </el-form-item>
                <br />
                <el-form-item
                    v-if="
                        ['1', '2', '3'].indexOf(formdata.saveJson.bzhgbzt) > -1
                    "
                    label="国家文物局批准文号"
                    prop="gjwwjpzwh"
                    :class="{ 'short-input': gjwwjFileList.length === 0 }"
                >
                    <el-input
                        v-model="formdata.saveJson.gjwwjpzwh"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                    <el-upload
                        v-if="gjwwjFileList.length === 0"
                        :disabled="!isEdit"
                        :show-file-list="false"
                        :accept="'.' + fileType.join(',.')"
                        class="upload-demo"
                        :action="`${BASE_URL}UpLoad/FileSave/`"
                        :on-error="
                            (e, file, fileList) => {
                                errorUpload('gjwwjFileList', e, file, fileList);
                            }
                        "
                        :on-progress="
                            (e, file, fileList) => {
                                progressUpload(
                                    'gjwwjFileList',
                                    e,
                                    file,
                                    fileList
                                );
                            }
                        "
                        :on-success="
                            (response, file) => {
                                successFile(
                                    'gjwwjFileList',
                                    response,
                                    file,
                                    '1901'
                                );
                            }
                        "
                        multiple
                    >
                        <el-button size="small" type="primary"
                            >文件上传</el-button
                        >
                    </el-upload>
                    <ul class="file-list">
                        <li
                            v-for="(item, i) in gjwwjFileList"
                            :key="i"
                            :class="item.percentage ? 'high-li' : ''"
                        >
                            <i
                                v-if="isEdit"
                                class="listRemove"
                                title="删除"
                                @click="delFile('gjwwjFileList', i)"
                                >×</i
                            >
                            <a
                                target="_blank"
                                :href="item.FilePath"
                                :title="item.FileName"
                            >
                                <span>{{ item.FileName }}</span>
                            </a>
                            <el-progress
                                v-if="item.percentage"
                                :percentage="item.percentage"
                            ></el-progress>
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item
                    v-if="['1', '2'].indexOf(formdata.saveJson.bzhgbzt) > -1"
                    label="省级政府公布文号"
                    prop="sjzfgbwh"
                    :class="{ 'short-input': sjzfFileList.length === 0 }"
                >
                    <el-input
                        v-model="formdata.saveJson.sjzfgbwh"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                    <el-upload
                        v-if="sjzfFileList.length === 0"
                        :disabled="!isEdit"
                        :show-file-list="false"
                        :accept="'.' + fileType.join(',.')"
                        class="upload-demo"
                        :action="`${BASE_URL}UpLoad/FileSave/`"
                        :on-error="
                            (e, file, fileList) => {
                                errorUpload('sjzfFileList', e, file, fileList);
                            }
                        "
                        :on-progress="
                            (e, file, fileList) => {
                                progressUpload(
                                    'sjzfFileList',
                                    e,
                                    file,
                                    fileList
                                );
                            }
                        "
                        :on-success="
                            (response, file) => {
                                successFile(
                                    'sjzfFileList',
                                    response,
                                    file,
                                    '1902'
                                );
                            }
                        "
                        multiple
                    >
                        <el-button size="small" type="primary"
                            >文件上传</el-button
                        >
                    </el-upload>
                    <ul class="file-list">
                        <li
                            v-for="(item, i) in sjzfFileList"
                            :key="i"
                            :class="item.percentage ? 'high-li' : ''"
                        >
                            <i
                                v-if="isEdit"
                                class="listRemove"
                                title="删除"
                                @click="delFile('sjzfFileList', i)"
                                >×</i
                            >
                            <a
                                target="_blank"
                                :href="item.FilePath"
                                :title="item.FileName"
                            >
                                <span>{{ item.FileName }}</span>
                            </a>
                            <el-progress
                                v-if="item.percentage"
                                :percentage="item.percentage"
                            ></el-progress>
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item
                    v-if="['1', '2'].indexOf(formdata.saveJson.bzhgbzt) > -1"
                    label="规划文本文件"
                    prop="ghwbwj"
                >
                    <el-upload
                        v-if="ghwbwjFileList.length === 0"
                        :disabled="!isEdit"
                        :show-file-list="false"
                        :accept="'.' + fileType.join(',.')"
                        class="upload-demo"
                        :action="`${BASE_URL}UpLoad/FileSave/`"
                        :on-error="
                            (e, file, fileList) => {
                                errorUpload(
                                    'ghwbwjFileList',
                                    e,
                                    file,
                                    fileList
                                );
                            }
                        "
                        :on-progress="
                            (e, file, fileList) => {
                                progressUpload(
                                    'ghwbwjFileList',
                                    e,
                                    file,
                                    fileList
                                );
                            }
                        "
                        :on-success="
                            (response, file) => {
                                successFile(
                                    'ghwbwjFileList',
                                    response,
                                    file,
                                    '1903'
                                );
                            }
                        "
                        multiple
                    >
                        <el-button size="small" type="primary"
                            >文件上传</el-button
                        >
                    </el-upload>
                    <ul class="file-list file-ghwbwj-list">
                        <li
                            v-for="(item, i) in ghwbwjFileList"
                            :key="i"
                            :class="item.percentage ? 'high-li' : ''"
                        >
                            <i
                                v-if="isEdit"
                                class="listRemove"
                                title="删除"
                                @click="delFile('ghwbwjFileList', i)"
                                >×</i
                            >
                            <a
                                target="_blank"
                                :href="item.FilePath"
                                :title="item.FileName"
                            >
                                <span>{{ item.FileName }}</span>
                            </a>
                            <el-progress
                                v-if="item.percentage"
                                :percentage="item.percentage"
                            ></el-progress>
                        </li>
                    </ul>
                </el-form-item>
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="metaData"
                    basicType="document"
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "protection_management_plan",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            BASE_URL: window.REQUEST_URL,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                bzhgbzt: [
                    {
                        required: true,
                        message: "请选择编制和公布状态",
                        trigger: "change",
                    },
                ],
                sfwxb: [
                    {
                        required: true,
                        message: "请选择是否为修编",
                        trigger: "change",
                    },
                ],
            },
            pickerOptionsend: {
                disabledDate: (time) => {
                    if (this.formdata.saveJson.ghksnf) {
                        return (
                            time.getFullYear() < this.formdata.saveJson.ghksnf
                        );
                    }
                },
            },
            fileType: [
                "pdf",
                "doc",
                "docx",
                "xls",
                "xlsx",
                "txt",
                "zip",
                "rar",
            ],
            gjwwjFileList: [],
            sjzfFileList: [],
            ghwbwjFileList: [],
            metaData: [],
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    mc: "",
                    zzbzdw: "",
                    bzdw: "",
                    ghksnf: "",
                    ghjznf: "",
                    bzhgbzt: "",
                    sfwxb: "",
                    gjwwjpzwh: "",
                    sjzfgbwh: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "19",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    watch: {
        metaData() {},
    },
    mounted() {},
    methods: {
        progressUpload(data, e, file, fileList) {
            let hasThis = this[data].filter((item) => {
                return item.uid === file.uid;
            });
            if (hasThis.length === 0) {
                this[data].push({
                    FileName: file.name,
                    uid: file.uid,
                    percentage: Math.floor(file.percentage),
                });
            } else {
                hasThis[0].percentage = Math.floor(file.percentage);
            }
        },
        errorUpload(data, error, file, fileList) {
            this.percentage = 0;
            let hasThis = this[data].map((item) => {
                return item.uid === file.uid;
            });
            this[data].splice(hasThis.indexOf(true), 1);
            this.$message({
                message: "上传失败",
                type: "error",
            });
        },
        successFile(data, response, file, code) {
            let _this = this;
            if (response.IsSuccess) {
                let current = _this[data].filter((item) => {
                    return item.uid === file.uid;
                });
                let res = response.ResultValue[0];
                current[0].fjmc = res.FileName;
                current[0].path = res.FilePath;
                current[0].jcsjid = res.Guid;
                current[0].code = code;
                current[0].wdmc = res.FileName.substring(
                    0,
                    res.FileName.lastIndexOf(".")
                );
                this.formdata.metaJson.push(current[0]);
                current[0].percentage = 0;
            }
        },
        delFile(data, index) {
            let id = this[data][index].jcsjid;
            console.log(id);
            this.formdata.metaJson.forEach((item, ind) => {
                if (item.jcsjid === id) {
                    this.formdata.metaJson.splice(ind, 1);
                }
            });
            this.deleteFileID.push(id);
            this[data].splice(index, 1);
        },
        // 处理获取到的附件列表
        getMetaJsonData() {
            this.gjwwjFileList = [];
            this.sjzfFileList = [];
            this.ghwbwjFileList = [];
            this.metaData = [];
            let indexArr = [];
            let metaJson = this.formdata.metaJson;
            metaJson.forEach((item, index) => {
                item.FileName = item.fjmc;
                item.FilePath = item.path;
                if (item.lx === "1901") {
                    // 国家文物局批准文号附件
                    item.code = item.lx;
                    this.gjwwjFileList.push(item);
                } else if (item.lx === "1902") {
                    // 省级政府公布文号附件
                    item.code = item.lx;
                    this.sjzfFileList.push(item);
                } else if (item.lx === "1903") {
                    // 规划文本文件附件
                    item.code = item.lx;
                    this.ghwbwjFileList.push(item);
                } else if (item.lx === "3") {
                    // 规划文件
                    item.code = "JCSJ";
                    this.metaData.push(item);
                    indexArr.push(index);
                }
            });
            indexArr.reverse().forEach((item) => {
                this.formdata.metaJson.splice(item, 1);
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.short-input {
    .el-input {
        width: calc(100% - 90px);
        margin-right: 10px;
    }
    .upload-demo {
        display: inline-block;
    }
}

.file-list {
    li {
        background: #ddeaff;
        border-radius: 4px;
        height: 40px;
        margin-top: 5px;
        padding-left: 10px;
        position: relative;
        &.high-li {
            height: 50px;
            .el-progress {
                margin-top: -10px;
            }
        }
        i.listRemove {
            position: absolute;
            top: 3px;
            right: 8px;
            font-size: 22px;
            color: #20a0ff;
            font-style: normal;
            cursor: pointer;
        }
        a {
            display: block;
            width: calc(100% - 25px);
            height: 40px;
            line-height: 40px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #20a0ff;
            cursor: pointer;
            span {
                width: 100%;
                font-size: 14px;
                color: #20a0ff;
                word-wrap: break-word;
            }
        }
    }
}
.file-ghwbwj-list {
    li {
        margin-top: 0;
    }
}
</style>
